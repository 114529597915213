import React from 'react';
import { Field } from 'react-final-form';
import Select from 'react-select';

import { Required } from '../Required';
import { sectors } from '../../utils/constants';
import { createOption } from '../../utils/form';

import css from './Form.module.css';

const options = sectors.map(createOption);

export const FieldSectors = ({ validate, disabled, required, name = 'sectors', max = 3 }) => (
  <>
    <label htmlFor={name}>Zadajte sektor / aký tovar alebo službu ponúkate (max. 3) {required && <Required />}</label>
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        const count = (input.value || []).length;
        const maxReached = count >= max;

        const handleOnChange = (value, { action }) => {
          if (action === 'select-option' && maxReached) {
            return;
          }

          let newFieldValue = undefined;

          if (value && value.length > 0) {
            newFieldValue = value.map(({ value }) => value);
          }

          input.onChange(newFieldValue);
        };

        return (
          <Select
            inputId={name}
            name={input.name}
            placeholder="Vyhľadať sektor"
            className={`${css.select}${meta.error && meta.touched ? ' error' : ''}`}
            classNamePrefix="react-select"
            isMulti={true}
            options={maxReached ? [] : options}
            noOptionsMessage={(_) => (maxReached ? `Môžte vybrať maximálne ${max} položky` : 'Žiadne položky na výber')}
            isDisabled={disabled}
            onChange={handleOnChange}
            onFocus={input.onFocus}
            onBlur={input.onBlur}
            value={count > 0 ? input.value.map(createOption) : null}
          />
        );
      }}
    </Field>
  </>
);
