import React from 'react';
import firebase from 'gatsby-plugin-firebase';
import { useDocument } from 'react-firebase-hooks/firestore';
import { Form } from 'react-final-form';
import { toast } from 'react-toastify';

import NotFound from '../../pages/404';
import { Meta } from '../../components/Meta';
import { Main } from '../../components/Main';

import { FieldSectors } from '../../components/Item/FieldSectors';
import { FieldName } from '../../components/Item/FieldName';
import { FieldPhone } from '../../components/Item/FieldPhone';
import { FieldEmail } from '../../components/Item/FieldEmail';
import { FieldWeb } from '../../components/Item/FieldWeb';
import { FieldArea } from '../../components/Item/FieldArea';
import { FieldHours } from '../../components/Item/FieldHours';
import { FieldDescription } from '../../components/Item/FieldDescription';
import { Required } from '../../components/Required';

import { printError } from '../../utils';
import { required } from '../../utils/form';

import css from './Item.module.css';

const title = 'Upraviť položku';

export const EditItem = ({ id }) => {
  const [item, loading, error] = useDocument(firebase.firestore().collection('services').doc(id));

  const onSubmit = async ({ name, description, phone, email, web, hours, sectors, area }) => {
    // TODO: Runs spinner
    try {
      // Fields without values are `undefined`, need to remove them from the document
      await item.ref.update({
        name: name ? name : firebase.firestore.FieldValue.delete(),
        description: description ? description : firebase.firestore.FieldValue.delete(),
        phone: phone ? phone : firebase.firestore.FieldValue.delete(),
        email: email ? email : firebase.firestore.FieldValue.delete(),
        web: web ? web : firebase.firestore.FieldValue.delete(),
        hours: hours ? hours : firebase.firestore.FieldValue.delete(),
        sectors: sectors ? sectors : firebase.firestore.FieldValue.delete(),
        area: area ? area : firebase.firestore.FieldValue.delete(),
      });
      toast(`Položka "${name}" bola úspešne upravená`, { type: toast.TYPE.SUCCESS });
    } catch (e) {
      printError(e);
      toast('Položku sa nepodarilo upraviť', { type: toast.TYPE.ERROR });
      throw e;
    } finally {
      // TODO: remove spinner
    }
  };

  if (error) {
    return <NotFound />;
  }

  return (
    <Main>
      <Meta title={title} />
      <h1>{title}</h1>
      <Form
        onSubmit={onSubmit}
        initialValues={loading ? {} : item.data()}
        render={({ handleSubmit, invalid, submitting, pristine }) => {
          return (
            <form onSubmit={handleSubmit} className={css.form}>
              <ul className={css.formList}>
                <li>
                  <FieldSectors disabled={loading || submitting} validate={required} required />
                </li>
                <li>
                  <FieldName disabled={loading || submitting} validate={required} required />
                </li>
                <li>
                  <FieldPhone disabled={loading || submitting} />
                </li>
                <li>
                  <FieldEmail disabled={loading || submitting} />
                </li>
                <li>
                  <FieldWeb disabled={loading || submitting} />
                </li>
              </ul>
              <ul className={css.formList}>
                <li>
                  <FieldArea disabled={loading || submitting} validate={required} required />
                </li>
                <li>
                  <FieldHours disabled={loading || submitting} validate={required} required />
                </li>
                <li>
                  <FieldDescription disabled={loading || submitting} validate={required} required />
                </li>

                <li>
                  <button type="submit" disabled={invalid || submitting || pristine}>
                    Upraviť
                  </button>
                </li>
              </ul>
            </form>
          );
        }}
      />
      <p>
        <small>
          <Required /> Označené polia sú povinné.
        </small>
      </p>
    </Main>
  );
};
