import React from 'react';
import { Field } from 'react-final-form';

import { Required } from '../Required';

export const FieldName = ({ validate, disabled, required }) => (
  <>
    <label htmlFor="name">Meno alebo názov firmy {required && <Required />}</label>
    <Field name="name" validate={validate}>
      {({ input, meta }) => (
        <input
          {...input}
          disabled={disabled}
          id="name"
          required={required}
          placeholder="Ján Novák"
          className={meta.error && meta.touched ? 'error' : ''}
        />
      )}
    </Field>
  </>
);
