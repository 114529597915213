import React from 'react';
import { Field } from 'react-final-form';

import { Required } from '../Required';

export const FieldPhone = ({ validate, disabled, required }) => (
  <>
    <label htmlFor="phone">
      Telefónne číslo, cez ktoré Vás môže zákazník kontaktovať. Bez predvoľby a bez medzier, tak ako je v predlohe.{' '}
      {required && <Required />}
    </label>
    <Field name="phone" validate={validate}>
      {({ input, meta }) => (
        <input
          {...input}
          disabled={disabled}
          id="phone"
          required={required}
          type="number"
          placeholder="0912123123"
          className={meta.error && meta.touched ? 'error' : ''}
        />
      )}
    </Field>
  </>
);
