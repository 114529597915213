import React from 'react';
import { Redirect, useLocation } from '@reach/router';
import { useAuth } from '../app';
import { isBrowser } from '../utils';
import { routes } from '../utils/constants';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { state } = useAuth();
  const location = useLocation();

  if (!isBrowser() || state === 'idle' || state === 'pending') {
    return null;
  }

  if (state === 'authenticated') {
    return <Redirect to={routes.profile} noThrow />;
  }

  if (state !== 'verified') {
    return <Redirect to={routes.login} state={{ redirect: location.pathname }} noThrow />;
  }

  return <Component {...rest} />;
};
