import React from 'react';
import { Field } from 'react-final-form';

import { Required } from '../Required';

export const FieldHours = ({ validate, disabled, required }) => (
  <>
    <label htmlFor="hours">
      Otváracie hodiny alebo hodiny. počas ktorých Vás zákazník môže kontaktovať {required && <Required />}
    </label>
    <Field name="hours" validate={validate}>
      {({ input, meta }) => (
        <input
          {...input}
          disabled={disabled}
          id="hours"
          required={required}
          placeholder="Po-Ne 8:00 - 15:00"
          className={meta.error && meta.touched ? 'error' : ''}
        />
      )}
    </Field>
  </>
);
