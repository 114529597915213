import React, { useEffect, useState } from 'react';
import { Field, useField } from 'react-final-form';
import Select, { createFilter } from 'react-select';

import { CustomSelectOption, slovakiaOption } from './CustomSelectOption';
import { Required } from '../Required';
import { cities } from '../../utils/cities';
import { createOption } from '../../utils/form';
import { stripDiacritics } from '../../utils/string';

import css from './Form.module.css';

const options = cities.map((city) => ({
  ...createOption(city),
  name: stripDiacritics(city),
}));

options.unshift(slovakiaOption);

const stringifyOption = (option) => `${option.label} ${option.data.name}`;

export const FieldArea = ({ validate, disabled, required, name = 'area' }) => {
  const {
    meta: { initial: initalValue },
  } = useField(name);

  const [slovakia, setSlovakia] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    setSlovakia(initalValue && initalValue.includes(slovakiaOption.value));
  }, [initalValue]);

  return (
    <>
      <label htmlFor={name}>Miesto doručenia (celé Slovensko alebo konkrétne mestá) {required && <Required />}</label>
      <Field name={name} validate={validate}>
        {({ input, meta }) => {
          const count = (input.value || []).length;

          const handleOnInputChange = (value) => (value ? setMenuOpen(value.length > 2) : setMenuOpen(false));

          const handleOnChange = (value) => {
            let newFieldValue = undefined;
            setSlovakia(false); // "default" state

            if (value && value.length > 0) {
              newFieldValue = value.map(({ value }) => value);
            }

            if (newFieldValue && newFieldValue.includes(slovakiaOption.value)) {
              newFieldValue = [slovakiaOption.value];
              setSlovakia(true);
            }

            input.onChange(newFieldValue);
          };

          return (
            <Select
              inputId={name}
              name={input.name}
              placeholder="Vyhľadať mesto"
              className={`${css.select}${meta.error && meta.touched ? ' error' : ''}`}
              classNamePrefix="react-select"
              noOptionsMessage={(_) =>
                slovakia
                  ? 'Môžte vybrať celé Slovensko alebo konkrétne mestá'
                  : menuOpen
                  ? 'Žiadne položky na výber'
                  : null
              }
              isMulti={true}
              options={slovakia ? [slovakiaOption] : menuOpen ? options : [slovakiaOption]}
              filterOption={createFilter({ ignoreAccents: false, stringify: stringifyOption })}
              isDisabled={disabled}
              components={{ DropdownIndicator: null, Option: CustomSelectOption }}
              onInputChange={handleOnInputChange}
              onChange={handleOnChange}
              onFocus={input.onFocus}
              onBlur={input.onBlur}
              value={count > 0 ? input.value.map((value) => createOption(value)) : null}
            />
          );
        }}
      </Field>
    </>
  );
};
