import React from 'react';
import { Field } from 'react-final-form';

import { Required } from '../Required';

export const FieldWeb = ({ validate, disabled, required }) => (
  <>
    <label htmlFor="web">Vaša webstránka (alebo napríklad stránka na Facebooku) {required && <Required />}</label>
    <Field name="web" validate={validate}>
      {({ input, meta }) => (
        <input
          {...input}
          disabled={disabled}
          id="web"
          required={required}
          pattern="^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$"
          placeholder="www.vasweb.sk"
          className={meta.error && meta.touched ? 'error' : ''}
        />
      )}
    </Field>
  </>
);
