import React from 'react';
import { Field } from 'react-final-form';

import { Required } from '../Required';

export const FieldEmail = ({ validate, disabled, required }) => (
  <>
    <label htmlFor="email">
      Email, prostredníctvom ktorého sa môže zákazník s Vami spojiť {required && <Required />}
    </label>
    <Field name="email" validate={validate}>
      {({ input, meta }) => (
        <input
          {...input}
          disabled={disabled}
          id="email"
          required={required}
          type="email"
          placeholder="vas@email.sk"
          className={meta.error && meta.touched ? 'error' : ''}
        />
      )}
    </Field>
  </>
);
