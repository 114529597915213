import React from 'react';
import { Router, Redirect } from '@reach/router';

import { PrivateRoute } from '../components/PrivateRoute';
import { AddItem } from '../scenes/item/AddItem';
import { EditItem } from '../scenes/item/EditItem';
import { routes } from '../utils/constants';

export default () => {
  return (
    <Router basepath={routes.item}>
      <PrivateRoute path={routes.add} component={AddItem} />
      <PrivateRoute path="/:id" component={EditItem} />
      <Redirect from="/" to={`${routes.item}${routes.add}`} default noThrow />
    </Router>
  );
};
