import React from 'react';
import firebase from 'gatsby-plugin-firebase';
import { Form } from 'react-final-form';
import { toast } from 'react-toastify';

import { Meta } from '../../components/Meta';
import { Main } from '../../components/Main';
import { useAuth } from '../../app';

import { FieldSectors } from '../../components/Item/FieldSectors';
import { FieldName } from '../../components/Item/FieldName';
import { FieldPhone } from '../../components/Item/FieldPhone';
import { FieldEmail } from '../../components/Item/FieldEmail';
import { FieldWeb } from '../../components/Item/FieldWeb';
import { FieldArea } from '../../components/Item/FieldArea';
import { FieldHours } from '../../components/Item/FieldHours';
import { FieldDescription } from '../../components/Item/FieldDescription';
import { Required } from '../../components/Required';

import { printError } from '../../utils';
import { required } from '../../utils/form';

import css from './Item.module.css';

const title = 'Pridať novú položku';

export const AddItem = () => {
  const { user } = useAuth();

  const onSubmit = async (values, form) => {
    // TODO: Runs spinner
    try {
      await firebase
        .firestore()
        .collection('services')
        .add({
          ...values,
          published: true,
          author: user.uid,
          created: firebase.firestore.FieldValue.serverTimestamp(),
        });

      // @see https://github.com/final-form/react-final-form/issues/21#issuecomment-604005303
      setTimeout(() => {
        Object.keys(values).forEach((key) => form.resetFieldState(key));
        form.reset();
      });

      toast(`Položka "${values.name}" bola úspešne pridaná`, { type: toast.TYPE.SUCCESS });
    } catch (e) {
      printError(e);
      toast('Položku sa nepodarilo pridať', { type: toast.TYPE.ERROR });
      throw e;
    } finally {
      // TODO: remove spinner
    }
  };

  return (
    <Main>
      <Meta title={title} />
      <h1>{title}</h1>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form, invalid, submitting }) => (
          <form onSubmit={handleSubmit} className={css.form}>
            <ul className={css.formList}>
              <li>
                <FieldSectors disabled={submitting} validate={required} required />
              </li>
              <li>
                <FieldName disabled={submitting} validate={required} required />
              </li>
              <li>
                <FieldPhone disabled={submitting} />
              </li>
              <li>
                <FieldEmail disabled={submitting} />
              </li>
              <li>
                <FieldWeb disabled={submitting} />
              </li>
            </ul>

            <ul className={css.formList}>
              <li>
                <FieldArea disabled={submitting} validate={required} required />
              </li>
              <li>
                <FieldHours disabled={submitting} validate={required} required />
              </li>
              <li>
                <FieldDescription disabled={submitting} validate={required} required />
              </li>

              <li>
                <button type="submit" disabled={invalid || submitting}>
                  Uložiť
                </button>
              </li>
            </ul>
          </form>
        )}
      />
      <p>
        <small>
          <Required /> Označené polia sú povinné.
        </small>
      </p>
    </Main>
  );
};
