import React from 'react';
import { Field } from 'react-final-form';

import { Required } from '../Required';

export const FieldDescription = ({ validate, disabled, required }) => (
  <>
    <label htmlFor="description">Popis produktu/služby {required && <Required />}</label>
    <Field name="description" validate={validate}>
      {({ input, meta }) => (
        <textarea
          {...input}
          disabled={disabled}
          id="description"
          required={required}
          placeholder="Príklad: farby, laky, interiér, exteriér. Rozvoz vieme zabezpečiť do 30 km. "
          className={meta.error && meta.touched ? 'error' : ''}
          style={{ marginBottom: 0 }}
        />
      )}
    </Field>
    <p style={{ margin: '0 0 1rem 0' }}>
      <small>
        Zadajte aj informáciu o tom, do akej vzdialenosti rozvážate tovar, poskytujete službu. Pridajte aj váš príbeh
        ako vám, využitím vašich služieb alebo nákupom tovaru, zákazníci pomôžu.
      </small>
    </p>
  </>
);
